import React from 'react';
import Landing from '../components/Landing';
const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content = md.render(dedent`# Titre RNCP de Développeur Web et Web Mobile - RNCP37674

**Dernière mise à jour : le 20/02/2024**

La formation Développeur Web de 3 à 6 mois, dispensée par Le Reacteur permet de passer titre RNCP de Développeur Web et Web Mobile de niveau 5 (Bac+2), dont le certificateur est le Ministère du travail, de l'emploi et de l'insertion. Sur cette page sont mentionnées les informations importantes concernant ce titre RNCP.

<p class="alert alert-info">🏆 Fin 2023, Le Reacteur est devenu organisme certificateur pour le titre RNCP37674. Les statistiques de réussite à l'examen seront communiquées au cours de l'année 2025.</p>

## Référentiel

Le titre RNCP de Développeur Web et Web Mobile est référencé au répertoire national des certifications professionnelles, sous le numéro RNCP37674, en date du 12/06/2023. Le référentiel complet peut être consulté à l'adresse suivante : <a target="_blank" aria-label="France Compétences" href="https://www.francecompetences.fr/recherche/rncp/37674/" rel="noopener noreferrer">francecompetences.fr/recherche/rncp/37674</a>.

## Compétences attestées

L'obtention du titre RNCP permet d'attester 2 blocs de compétences :

**1. Développer la partie front-end d'une application Web ou Web mobile sécurisée :**
  - Installer et configurer son environnement de travail en fonction du projet Web ou Web mobile
  - Maquetter des interfaces utilisateur Web ou Web mobile
  - Réaliser des interfaces utilisateur statiques Web ou Web mobile
  - Développer la partie dynamique des interfaces utilisateur Web ou Web mobile

**2. Développer la partie back-end d'une application Web ou Web mobile sécurisée :**
  - Mettre en place une base de données relationnelle
  - Développer des composants d'accès aux données SQL et NoSQL
  - Développer des composants métier côté serveur
  - Documenter le déploiement d'une application dynamique Web ou Web mobile

##  Secteurs d'activité et type d'emploi

Une fois le titre RNCP obtenu, le candidat sera à même de travailler dans certains secteurs d'activité en lien avec ses compétences. Ceux-ci regroupent : 

- Les Entreprises de Services Numériques (ESN ou ex SSII) réalisant des prestations de développement d'applications, en régie ou au forfait.
- Les entreprise de solutions logicielles
- Les structures utilisatrices, de type entreprise du secteur privé ou public, possédant un service dédié aux études et aux développements informatiques
- Les activités d'informaticien d'études indépendant

**Les emplois accessibles :**

- Développeur d'applications, développeur informatique
- Développeur back end, développeur front end, développeur full stack
- Développeur d'applications mobiles
- Développeur Web, développeur Web mobile, développeur Web et Web mobile

## Quel est le déroulé de l'examen ? 

### Questionnaire professionnel (30 minutes)
Le candidat étudie une documentation technique rédigée en anglais. Il répond à :
- deux questions fermées à choix unique posées en français
- deux questions ouvertes posées en anglais et amenant des réponses courtes, en rédigeant la réponse en anglais.
  
### Présentation d’un projet réalisé en amont de la session (35 minutes)
En amont de la session, le candidat a réalisé un ou plusieurs projets et a préparé un dossier de projet ainsi qu’un support de présentation.

### Entretien technique (40 minutes)
Le jury questionne le candidat sur la base de son dossier de projet et de sa présentation orale.

### Entretien final (15 minutes)
Le jury évalue la compréhension, la vision et la culture professionnelle du métier.

Durée totale de l’examen pour le candidat : 2 heures

## Prérequis au passage de l'examen 

Le titre RNCP37674 est composé de 2 blocs de compétences. Ces derniers peuvent être passés/validés simultanément ou de manière indépendante, par les apprenants ayant suivi la formation Développeur Web et Web Mobile dispensée par Le Reacteur. Aussi, la réalisation d’un stage de minimum 2 mois est obligatoire.

<p class="alert alert-info">⚠️ Il n'y a aucune équivalence et/ou passerelle (certifications professionnelles, certifications ou habilitations) permettant d'obtenir le titre RNCP de Développeur Web et Web Mobile.</p>

## Valider une VAE

La VAE permet d'obtenir, en totalité ou en partie le titre RNCP. 12 mois d'expérience professionnelle sont nécessaires pour faire une demande de VAE. Pour comprendre ce qu'est une VAE nous vous invitons à vous rendre à l'adresse suivante : <a target="_blank" aria-label="VAE" href="https://www.education.gouv.fr/la-validation-des-acquis-de-l-experience-vae-3077">education.gouv.fr/la-validation-des-acquis-de-l-experience-vae-3077</a>.

## Le coût du passage de l'examen du titre RNCP

Les frais relatifs au montage du dossier auprès du Ministère du travail, de l'emploi et de l'insertion, ainsi qu'à la rémunération du jury s'élèvent à 600 euros et 1.200 euros pour une VAE.
Si le candidat échoue à son examen, et en cas de repassage, ce dernier devra de nouveau s'acquitter de ces frais. 

## Quelle est la procédure pour passer le titre RNCP 

Tout d'abord, il conviendra de nous contacter par mail pour nous informer de votre souhait de passer l'examen.
À la suite de ça, nous vous proposerons, une date de passage de l'examen. Il y a deux sessions d'examen par an qui se déroulent respectivement en août et septembre. Une fois la date de votre passage validée, votre mémoire devra être transmis au jury avant la date de l'examen (nous nous chargerons de cette procédure). À partir de là, le mémoire ne pourra plus être modifié.
Vous recevrez une convocation pour passer l'examen. Tout retard le jour de l'examen est disqualifiant.
Une fois l'examen passé, le jury aura 1 mois pour délibérer et nous transmettre sa décision. Vous serez informé par mail du résultat de l'examen et, en cas de réussite, il vous sera envoyé par voie postale.

## Aménagements pour les personnes en situation de handicap 

Toute personne en situation de handicap se verra proposer des solutions visant à lui faciliter le passage de l'examen du titre RNCP. Si le candidat a des difficultés pour se déplacer sur le lieu de l'examen, il lui sera proposé une évaluation à distance, grâce à la visio-conférence (Zoom ou équivalent). Notre équipe rentre en relation avec tous les candidats au titre RNCP, avant de valider une date de passage. C'est à ce moment là que les apprenants sont informés des aménagements éventuels, en fonction de la situation du candidat. Notre équipe attache une importance particulière à l'égalité des droits et des chances ; notre souhait est de faciliter le passage de l'examen.

Si vous pensez que cela vous concerne, vous devrez justifier de votre situation de handicap en nous fournissant tout document administratif délivré par une autorité compétente (RQTH, AAH…).

Notre contact référent handicap : **contact**[arobase]**lereacteur**[point]**io**.

`);

const Page = React.memo(({ location }) => {
  return (
    <Landing
      location={location}
      content={[{ type: 'md', content }]}
      pageName="Titre RNCP de Développeur Web et Web Mobile - RNCP37674"
      metaTitle="Titre RNCP de Développeur Web et Web Mobile | Le Reacteur"
    />
  );
});

export default Page;
